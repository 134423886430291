import React, { useState, useEffect, useContext } from 'react'
import {
    VStack,
    Flex,
    Divider,
    Image,
    Text,
    Button,
    Box,
    Link
} from '@chakra-ui/react';
import {
    useToast,
    useDisclosure
} from '@chakra-ui/react';
import { getPrompts } from '../actions/prompts';
import { SERVER_URL } from '../util/constant';
import { useCookies } from 'react-cookie';
import { AppContext } from '../provider/AppProvider';
import { Contact } from './Contact';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Sidebar(props) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const toast = useToast();
    const { modes, setModes, setFlag, user } = useContext(AppContext);
    const [cookies] = useCookies(['token', 'subscription']);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        setFlag(true);
        if (cookies.token == undefined || cookies.token == 'undefined') return navigate('/login');
        getPrompts(cookies.token, setModes, setFlag);
    }, []);

    const handleChangeModel = e => {
        const index = modes.findIndex(element => element.name == e.target.textContent && element.content == e.target.id);
        if (modes[index].pro) {
            if (user.subscription.plan == 'free') {
                return toast({
                    title: 'Error',
                    description: "To use it or other PRO chat modes, you need to have an active Subscription",
                    status: 'error',
                    duration: '3000',
                    isClosable: true,
                    position: 'top-right'
                })
            }
        }
        
        const intel_text = index < 0 ? '' : modes[index].intel_text ? modes[index].intel_text : '';
        const intel_docs = index < 0 ? '' : modes[index].intel_docs ? modes[index].intel_docs : '';
        props.setModel({
            name: e.target.textContent,
            content: index < 0 ? '' : modes[index].content + '  ' + intel_text + '   ' + intel_docs,
            img: index < 0 ? '' : modes[index].img,
            temperature: index < 0 ? 0.8 : modes[index].temperature,
            max_tokens: index < 0 ? 4096 : modes[index].max_tokens,
        });
    }
    return (
        <Box className='Sidebar'>
            <VStack>
                <Flex width={"100%"} alignItems={'center'} pl={"10px"} pb={4}>
                    <Image src='./logo.jpg' width={["3.5em", {'2xl': "5em"}, {xl: "4.5em"}, {lg: "4em"}, {md: "3.5em"}]} height={["3.5em", {'2xl': "5em"}, {xl: "4.5em"}, {lg: "4em"}, {md: "3.5em"}]} borderRadius={[20, {md: 23}]} />
                    <Text className='logo-text'>{t('app.logo')}</Text>
                </Flex>
                <Divider />
                <VStack w={"100%"} pl={"10px"} alignItems={'start'}>
                    <Text fontSize={"14px"} py={3}>({modes.length}&nbsp;{t('sidebar.header')})</Text>
                    <Box w={"100%"} h={["38vh", {md: "50vh"}]} maxH={["38vh", {md: "50vh"}]} mb={2} pl={"15px"} overflow={'scroll'} overflowX={'hidden'}>
                        {
                            modes.map((item, index) => (
                                <Link display={'flex'} alignItems={'center'} key={item.name+' '+index}>
                                    <Image src={SERVER_URL + item.img} w={'18px'} h={'18px'} mr={3} />
                                    <Text
                                        textOverflow={'ellipsis'}
                                        overflow={'hidden'}
                                        whiteSpace={'nowrap'}
                                        w={'95%'}
                                        align={'start'}
                                        fontSize={["14px", {md: "18px"}]}
                                        py={3}
                                        id={item.content}
                                        onClick={handleChangeModel}
                                    >
                                        {item.name}
                                    </Text>
                                </Link>
                            ))
                        }
                    </Box>
                </VStack>
                <Divider />
                <VStack w={"100%"} alignItems={'start'} pt={6} pl={"10px"}>
                    <Link className='link' href='https://docs.mindaiproject.com/' target='_blank' style={{textDecoration: 'none'}}>
                        <Text fontSize={['md', {md: 'lg'}]} lineHeight={1} >{t('app.about')}</Text>
                    </Link>
                    <Link className='link' href='https://docs.mindaiproject.com/policies/acceptable-usage' target='_blank' style={{textDecoration: 'none'}}>
                        <Text fontSize={['md', {md: 'lg'}]} lineHeight={1}>{t('app.privacy&policy')}</Text>
                    </Link>
                    <Link className='link' style={{textDecoration: 'none'}}>
                        <Text fontSize={['md', {md: 'lg'}]} onClick={onOpen}>{t('app.contact')}</Text>
                    </Link>
                    <Link className='link' w={'100%'} href='https://docs.mindaiproject.com/official-links/kyc-and-audit' target='_blank'>
                        <Button colorScheme={'white'} w={'100%'} my={3} px={[6, {lg: 16}]} variant={'outline'} borderRadius={10} fontSize={['12px', {lg: '16px'}]}>{t('app.exploremore')}</Button>
                    </Link>
                    <Text fontSize={"12px"}>{t('app.footer')}</Text>
                    {/* <Flex pt={5} alignItems={'center'}>
                        <Image color={'white'} src='./logo.webp' w={'35px'} h={"35px"} />
                        <Text pl={2} fontSize={['xl', '2xl']} fontWeight={'bold'}>Powered by OPENAI</Text>
                    </Flex> */}
                </VStack>
            </VStack>
            <Contact isOpen={isOpen} onClose={onClose} />
        </Box>
    )
}
