import React, {useState, useContext} from 'react'
import Sidebar from './Sidebar'
import { VStack, Spinner } from '@chakra-ui/react'
import Header from './Header'
import Content from './Content'
import {AppContext} from '../provider/AppProvider';

export default function Home() {
    const { flag } = useContext(AppContext);
    const [model, setModel] = useState({
        name: '',
        content: '',
        img: '',
        messages: [],
        max_tokens: 4096,
        temperature: 0.8
    });
    const handleChangeModel = value => {
        setModel({
            ...model,
            name: value.name,
            content: value.content,
            img: value.img,
            max_tokens: value.max_tokens,
            temperature: value.temperature
        });
    }

    const handleSetMessages = val => {
        setModel({
            ...model,
            messages: val.messages,
            name: val.mode,
            img: val.img
        });
    }

    return (
        <div className='Container'>
            {flag && <VStack position={'absolute'} zIndex={2000} w={'100%'} h={'100vh'} backgroundColor={'#ffffff70'}>
                <Spinner margin={'auto'} size='xl' thickness='5px' speed='0.65s' emptyColor='gray.200' color='blue.500'/>
            </VStack>}
            <VStack w={"25%"} h={"100vh"} display={['none', {md: 'block'}]}>
                <Sidebar setModel={handleChangeModel} />
            </VStack>
            <VStack w={"100%"} padding={8}>
                <Header setMessages={handleSetMessages} setModel={handleChangeModel}/>
                <Content model={model} />
            </VStack>
        </div>
    )
}
