import React, { useState, useEffect, useContext } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Box,
    Grid,
    GridItem,
    FormControl,
    InputGroup,
    InputLeftElement,
    Input,
    Button,
    CircularProgress,
    CircularProgressLabel,
    FormLabel,
    Stack,
    Center,
    Avatar,
    AvatarBadge,
    IconButton
} from '@chakra-ui/react'
import {
    chakra,
    useRadio,
    useRadioGroup,
    useToast
} from '@chakra-ui/react';
import {
    useCookies
} from 'react-cookie';
import { FaUserAlt } from "react-icons/fa";
import { changeLang, changeModel, changeName } from '../actions/auth';
import axios from 'axios';
import { API_URL } from '../util/constant';
import { useTranslation } from 'react-i18next';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { FileUpload } from 'primereact/fileupload';
import { AppContext } from '../provider/AppProvider';
const CFaUserAlt = chakra(FaUserAlt);

export default function Setting({ isOpen, onClose }) {
    const { t, i18n } = useTranslation("common");
    const toast = useToast();
    const {user, setUser} = useContext(AppContext);
    const [cookies, setCookie] = useCookies(['token', 'name', 'model', 'lang']);
    const [name, setName] = useState('');
    const [remain, setRemain] = useState({
        day: 0,
        hour: 0,
        min: 0,
        value: 0
    });
    const [id, setId] = useState('');
    const [data, setData] = useState({
        name: '',
        email: '',
        password: '',
        wallet: '',
        img: null
    });

    const RadioCard = (props) => {
        const { getInputProps, getRadioProps } = useRadio(props)

        const input = getInputProps()
        const checkbox = getRadioProps()

        return (
            <Box as='label'>
                <input {...input} />
                <Box
                    {...checkbox}
                    cursor='pointer'
                    borderWidth='1px'
                    borderRadius='md'
                    boxShadow='md'
                    _checked={{
                        bg: 'teal.600',
                        color: 'white',
                        borderColor: 'teal.600',
                    }}
                    _focus={{
                        boxShadow: 'outline',
                    }}
                    px={5}
                    py={3}
                >
                    {props.children}
                </Box>
            </Box>
        )
    }

    const RadioLanguageCard = (props) => {
        const { getInputProps, getRadioProps } = useRadio(props)

        const input = getInputProps()
        const checkbox = getRadioProps()

        return (
            <GridItem as='label'>
                <input {...input} />
                <Box
                    {...checkbox}
                    cursor='pointer'
                    borderWidth='1px'
                    borderRadius='md'
                    boxShadow='md'
                    _checked={{
                        bg: 'teal.600',
                        color: 'white',
                        borderColor: 'teal.600',
                    }}
                    _focus={{
                        boxShadow: 'outline',
                    }}
                    px={5}
                    py={3}
                >
                    {props.children}
                </Box>
            </GridItem>
        )
    }

    // const options = ['ChatGPT-3.5', 'ChatGPT-4', 'Gemini', 'Bittensor', 'Oracle']
    const options = [t('setting.model.type.gpt3.5'), t('setting.model.type.gpt4'), t('setting.model.type.gpt4o'), t('setting.model.type.oracle')]

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        defaultValue: cookies.model,
        onChange: e => handleChangeModel(e),
    })

    const handleChangeModel = (val) => {
        changeModel(cookies.token, { model: val });
        setCookie('model', val, { path: '/' });
        onClose();
    }

    const group = getRootProps()

    const { getRadioProps: getLanguageProps, getRootProps: getLanguageRootProps } = useRadioGroup({
        name: 'language',
        defaultValue: cookies.lang,
        onChange: e => handleChangeLang(e)
    })

    const handleChangeLang = (val) => {
        changeLang(cookies.token, { lang: val });
        i18n.changeLanguage(val);
        setCookie('lang', val, { path: '/' });
        onClose();
    }

    const groupLanguage = getLanguageRootProps();

    const languages = [
        { text: t('setting.language.type.en'), lang: 'en' },
        { text: t('setting.language.type.pt'), lang: 'pt' },
        { text: t('setting.language.type.es'), lang: 'es' },
        { text: t('setting.language.type.tr'), lang: 'tr' },
        { text: t('setting.language.type.id'), lang: 'id' },
        { text: t('setting.language.type.in'), lang: 'in' },
        { text: t('setting.language.type.fr'), lang: 'fr' },
        { text: t('setting.language.type.it'), lang: 'it' },
        { text: t('setting.language.type.de'), lang: 'de' },
        { text: t('setting.language.type.ru'), lang: 'ru' },
        { text: t('setting.language.type.ar'), lang: 'ar' },
        { text: t('setting.language.type.ir'), lang: 'ir' },
        { text: t('setting.language.type.jp'), lang: 'jp' },
        { text: t('setting.language.type.cn'), lang: 'cn' }
    ];

    const handleSetCookie = (name) => {
        setCookie('name', name, { path: '/' });
    }

    const handleChangeName = () => {
        changeName({ name }, cookies.token, handleSetCookie, errorMsg, successMsg);
        onClose();
    }

    const errorMsg = (msg) => {
        toast({
            title: 'Something went wrong!',
            description: msg,
            status: 'error',
            duration: '3000',
            isClosable: true,
            position: 'top-right'
        })
    }

    const successMsg = (msg) => {
        toast({
            title: 'Success!',
            description: msg,
            status: 'success',
            duration: '3000',
            isClosable: true,
            position: 'top-right'
        })
    }

    const getInfo = async () => {
        try {
            const response = await axios.post(`${API_URL}/user/info`, { message: '' }, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            });
            i18n.changeLanguage(response.data.language);

            let until = new Date(response.data.subscription.buyDate);
            switch (response.data.subscription.plan) {
                case "free":
                    setRemain({
                        day: 0,
                        hour: 0,
                        min: 0,
                        value: 0
                    })
                    return;
                case "weekly":
                    until.setDate(until.getDate() + 7);
                    break;
                case "monthly":
                    until.setMonth(until.getMonth() + 1);
                    break;
                case "yearly":
                    until.setFullYear(until.getFullYear() + 1);
                    break;
                default:
                    break;
            }
            setRemain({
                ...remain,
                total: until.getTime() - new Date(response.data.subscription.buyDate).getTime()
            })

            let diff = until.getTime() - new Date().getTime();

            setRemain({
                day: Math.floor(diff / (24 * 3600 * 1000)),
                hour: Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000)),
                min: Math.floor(((diff % (24 * 3600 * 1000)) % (3600 * 1000)) / (60 * 1000)),
                value: 100 - Math.floor(diff * 100 / (until.getTime() - new Date(response.data.subscription.buyDate).getTime()))
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveUserInfo = async () => {
        if (data.name === '' && data.email === '' && data.password === '' && data.wallet === '' && data.img === null) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', data.img);
            formData.append('data', JSON.stringify(data));

            const response = await axios.post(`${API_URL}/user/update`, formData, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            })
            const {success} = response.data;
            if (success) {
                toast({
                    title: 'Success!',
                    status: 'success',
                    isClosable: true,
                    position: 'top-right'
                })
                setUser({
                    ...user,
                    name: data.name !== '' ? data.name : user.name,
                    avatar: data.img === null ? user.img : response.data.data.avatar
                })
                onClose();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeFile = (e) => {
        setData({
            ...data,
            img: e.files[0]
        })
        console.log(e)
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'lg'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('setting.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs>
                        <TabList>
                            <Tab>{t('setting.model.title')}</Tab>
                            <Tab>{t('setting.language.title')}</Tab>
                            <Tab>{t('setting.profile.title')}</Tab>
                            <Tab onClick={getInfo}>{t('setting.time.title')}</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Grid {...group} templateColumns={'repeat(2, 1fr)'} gap={6}>
                                    {options.map((value) => {
                                        const radio = getRadioProps({ value })
                                        return (
                                            <RadioCard key={value} {...radio}>
                                                {value}
                                            </RadioCard>
                                        )
                                    })}
                                </Grid>
                            </TabPanel>
                            <TabPanel>
                                <Grid {...groupLanguage} templateColumns='repeat(2, 1fr)' gap={6}>
                                    {languages.map((value) => {
                                        const radio = getLanguageProps({ value: value.lang })
                                        return (
                                            <RadioLanguageCard key={value.lang} {...radio}>
                                                {value.text}
                                            </RadioLanguageCard>
                                        )
                                    })}
                                </Grid>
                            </TabPanel>
                            <TabPanel>
                                <FormControl id="userName">
                                    <FormLabel mt={2}>{t('setting.profile.icon')}</FormLabel>
                                    <Stack direction={['column', 'row']} spacing={6}>
                                        <Center>
                                            <Avatar size="xl" src={data.img === null ? '/avatar.jpg' : data.img.objectURL}>
                                                {data.img !== null && <AvatarBadge
                                                    as={IconButton}
                                                    size="sm"
                                                    rounded="full"
                                                    top="-10px"
                                                    colorScheme="red"
                                                    aria-label="remove Image"
                                                    icon={<SmallCloseIcon />}
                                                    onClick={() => setData({ ...data, img: null })}
                                                />}
                                            </Avatar>
                                        </Center>
                                        <Center w="full">
                                            {data.img === null && <FileUpload mode="basic" chooseLabel={t('setting.profile.change')} name="demo[]" accept="image/*" maxFileSize={10000000} onSelect={e => handleChangeFile(e)} />}
                                        </Center>
                                    </Stack>
                                </FormControl>
                                <FormControl id="userName">
                                    <FormLabel mt={2}>{t('setting.profile.name')}</FormLabel>
                                    <Input
                                        placeholder={t('setting.profile.name')}
                                        _placeholder={{ color: 'gray.500' }}
                                        type="text"
                                        value={data.name}
                                        onChange={e => setData({ ...data, name: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl id="email">
                                    <FormLabel mt={2}>{t('setting.profile.email')}</FormLabel>
                                    <Input
                                        placeholder="your-email@example.com"
                                        _placeholder={{ color: 'gray.500' }}
                                        type="email"
                                        value={data.email}
                                        onChange={e => setData({ ...data, email: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl id="wallet">
                                    <FormLabel mt={2}>{t('setting.profile.wallet')}</FormLabel>
                                    <Input
                                        placeholder="0x00000000000000000000000000000000000"
                                        _placeholder={{ color: 'gray.500' }}
                                        type="email"
                                        value={data.wallet}
                                        onChange={e => setData({ ...data, wallet: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl id="password">
                                    <FormLabel mt={2}>{t('setting.profile.password')}</FormLabel>
                                    <Input
                                        placeholder={t('setting.profile.password')}
                                        _placeholder={{ color: 'gray.500' }}
                                        type="password"
                                        value={data.password}
                                        onChange={e => setData({ ...data, password: e.target.value })}
                                    />
                                </FormControl>
                                <Stack spacing={6} direction={['column', 'row']} mt={2} justifyContent={'end'}>
                                    <Button
                                        bg={'teal.600'}
                                        color={'white'}
                                        _hover={{
                                            bg: 'teal.500',
                                        }}
                                        onClick={handleSaveUserInfo}
                                    >
                                        {t('setting.profile.save')}
                                    </Button>
                                </Stack>
                            </TabPanel>
                            <TabPanel justifyContent={'center'} display={'flex'}>
                                <CircularProgress value={remain.value} color='green.400' size={'sm'} thickness={'5px'}>
                                    <CircularProgressLabel fontSize={'4xl'}>
                                        {remain.day}d {remain.hour}h {remain.min}m
                                    </CircularProgressLabel>
                                </CircularProgress>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
